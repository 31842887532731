import React from "react";
import "../../css/style.css";
import {Box, Button, Typography, Grid } from '@mui/material';
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Contact = () => {

    return(
        <>
        <Header/>
        <Box className="inner_banner">
            <Box className="container">
                <Box className="inner_banner_title">
                    <Typography variant="h1">Contact Us</Typography>
                </Box>
            </Box>
        </Box>
        <Footer />
        </>
    )
}
export default Contact;