import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom";
import {Box, Button } from '@mui/material';
import logo from "../../assets/Images/white-logo.png";
import Stickylogo from "../../assets/Images/site-logo.png";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from 'react-scroll';

const Header = () => {
    
    const [sticky, setSticky] = useState("");

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
          window.removeEventListener("scroll", isSticky);
        };
      }, []);
    
      const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 50 ? "sticky-header" : "";
        setSticky(stickyClass);
        console.log(stickyClass);
      };
    
      const classes = `header-section d-xl-block ${sticky}`;
    
    return(
        <>
        {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className={classes}>
          <Box className='container'>
            <Box className="headeroverlay">
                <Box className="site_logo">
                    <img className="normal_logo"src={logo} alt="Rooster Logo" />
                    <img  className="sticky_logo" src={Stickylogo} alt="Rooster Logo" />
                </Box>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas 
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                >
                <Offcanvas.Header closeButton>
                    <img src={Stickylogo} alt="Rooster Logo" />
                </Offcanvas.Header>
                
                <Offcanvas.Body>
                    <Nav className="nav_link">
                        <Link to="Fetures" spy={true} smooth={true}>Features</Link>
                        <Link to="Contact" spy={true} smooth={true}>Contact</Link>
                        <Box className="header_button">
                            <Button className="standard_btn" disableElevation href="https://web.rooster.nz/login" target="_blank">Login</Button>
                            <Button className="standard_btn" disableElevation>Try Now</Button>
                        </Box>
                    </Nav>
                </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Box>
          </Box>
        </Navbar>
      ))}
        </>
    );
}
export default Header;