import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from './screens/Home/Home';
import Contact from './screens/Contact';

function App() {
  return (
    <>
    <BrowserRouter> 
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/contact' component={Contact} />
        </Switch>
    </BrowserRouter>
    </>
  );
}


export default App;
