import react from "react";
import { NavLink, useHistory } from "react-router-dom";
import Logo from "../../assets/Images/white-logo.png";
import { AiFillFacebook } from 'react-icons/ai';
import { AiFillTwitterSquare } from 'react-icons/ai';
import { AiFillLinkedin } from 'react-icons/ai';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { Grid, Typography, Box } from '@mui/material';
import { GrLocation } from 'react-icons/gr';
import { Link } from 'react-scroll';

const Footer = () => {

    return(
        <>
        <footer className="footer">
            <Box className="container">
                <Box className="footer-top">
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={4} sm={6}>
                            <Box className="footer_logo">
                                <img src={Logo} alt="Rooster Logo" />
                            </Box>
                            {/* <Typography variant="h4" className="footer_title">About</Typography> */}
                            <Typography variant="body1" mt={2} color="#b6c9dd" pr={3}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy 
                            text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </Typography>
                        </Grid>
                        <Grid item lg={2} md={2} sm={6}>
                            <Typography variant="h4" className="footer_title">Links</Typography>
                            <Box className="footer_link">
                                <Link to="Fetures" spy={true} smooth={true}>Features</Link>
                                <Link to="Contact" spy={true} smooth={true}>Contact</Link>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <Typography variant="h4" className="footer_title">Address</Typography>
                            <Box className="footer_lbox">
                                <Box className="location_box">
                                    <GrLocation />
                                    <Box className="address_text">
                                        <p><span>Australia </span>Level 1, 655 Sherwood Road Sherwood, QLD 4075 Australia</p>
                                    </Box>
                                </Box>
                                <Box className="location_box">
                                    <GrLocation />
                                    <Box className="address_text">
                                        <p><span>New Zealand </span>Suite 20/21 Wicksteed Terrace 225 Wicksteed Street 4501 WHANGANUI</p>
                                    </Box>
                                </Box>
                                <Box className="location_box">
                                    <GrLocation />
                                    <Box className="address_text">
                                        <p><span>India</span>Sector 16B, C-001/A2, Gautam Buddha Nagar, NOIDA, UP 201301</p>
                                    </Box>
                                </Box>
                                <Box className="location_box">
                                    <GrLocation />
                                    <Box className="address_text">
                                        <p><span>United Kingdom </span>96 Kensington High St, LONDON W8 4SG</p>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box className="footer_copyright">    
                    <Box className="ctext">
                        <p>All Rights Reserved © Rooster.nz and 
                            <span> Powered by <a target="_blank" href="https://proaxiom.com/" > Proaxiom</a></span>
                        </p>
                    </Box>
                    <Box className="footer_icon">
                        <NavLink to="/" className='facebook'><FaFacebookF/></NavLink>
                        <NavLink to="/" className='twitter'> <FaTwitter/></NavLink>
                        <NavLink to="/" className='linkdin'><FaLinkedinIn/></NavLink>
                    </Box>
                </Box>
            </Box>
        </footer>
        </>
    );
}

export default Footer;