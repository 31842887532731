import React from "react";
import "../../css/style.css";
import {Box, Button, Typography, Grid } from '@mui/material';
import Header from "../../components/Header";
import RoosterDeshboard from "../../assets/Images/rooster-screen.jpg";
import HoursImg from "../../assets/Images/icon/hours.svg";
import ReportsImg from "../../assets/Images/icon/Reports.svg";
import SurchargesImg from "../../assets/Images/icon/Surcharges.svg";
import About from "../../assets/Images/about-1.jpg";
import Footer from "../../components/Footer";
import Popper from "../../assets/Images/popper.png";
import Shift from "../../assets/Images/shift-screen.jpg";
import Calender from "../../assets/Images/calender-screen.jpg";
import Swap from "../../assets/Images/swap-screen.jpg";
import Sms from "../../assets/Images/sms-screen.jpg";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { BsChevronDown } from 'react-icons/bs';

const Home = () => {
    
    return(
        <>
        <Header />
        <Box className="hero_section">
            <Box className="container">
                <Grid container justifyContent="center">
                    <Grid lg={8}>
                        <Box className="banner_content">
                            <Typography variant="h1">For happy staff & successful<br/> management.</Typography>
                            <Typography variant="body1" color="white" mb={3}> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries </Typography>
                            <Button className="standard_btn">Demo</Button>    
                            <Box className="comingsoon_text">
                                <Typography variant="h3" color="white">Coming Soon New Design!! </Typography>
                                <img src={Popper} alt="Rooster New Design"/>
                                <img src={Popper} alt="Rooster New Design"/>
                                <img src={Popper} alt="Rooster New Design"/>
                            </Box>                
                        </Box>
                    </Grid>
                    <Grid lg={8}>
                        <Box className="banner_image">
                            <img className="movebounce" src={RoosterDeshboard} alt="Banner Image" />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box className="services space">
            <Box className="container">
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} className="service_box">
                        <Box className="service_content">
                            <Box className="s_icon">
                                <img src={HoursImg} alt="Services" />
                            </Box>
                            <Typography variant="h4">Manage Hours</Typography>
                            <Typography variant="body1">
                                Plus/minus-hours are calculated automatically. These are hours present or absent relative to the shifted hours.</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} className="service_box">
                        <Box className="service_content">
                            <Box className="s_icon">
                                <img src={ReportsImg} alt="Services" />
                            </Box>
                            <Typography variant="h4">Swap offers</Typography>
                            <Typography variant="body1">
                                User can make request for swap offer to the other user's shifts.</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} className="service_box">
                        <Box className="service_content">
                            <Box className="s_icon">
                                <img src={SurchargesImg} alt="Services" />
                            </Box>
                            <Typography variant="h4">Reports</Typography>
                            <Typography variant="body1">
                                Genrate valuable report for shifts data, shifts hours data, roster's data</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Box className="about_sectinon space">
            <Box className="container">
                <Box className="about_box">
                    <Grid container alignItems="center">
                        <Grid lg={6} md={6} xs={12}>
                            <img src={About} alt="About Us" />
                        </Grid>
                        <Grid lg={6} md={6} xs={12}>
                            <Box className="about_content">
                                <Typography variant="h3">Easily create employee shifts</Typography>
                                <Typography variant="body1">
                                    Many tenants deal with part-time or full time employees and changing work hours. 
                                    Rooster is an easy-to-use employee scheduling software that creates a flexible shifts for a longer or shorter period. 
                                    Schedules can also keep track of employee availability and absences, allowing the scheduler to incorporate any employee 
                                    requests.</Typography>
                                <Button className="standard_btn dark">Read More</Button>           
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
        
        <Box className="features_section space" id="Fetures">
            <Box className="title"><Typography variant="h2" >Features</Typography></Box>
            <Box className="features_overlay">
                <Box className="features_content">
                    <Box className="showcase">
                        <Typography variant="h3">Easily create employee<br/> shift</Typography>
                        <Typography variant="body1">Advanced chat option similar to Slack, messaging is much more simpler and all options available rightaway</Typography>
                    </Box>
                </Box>
                <Box className="features_img">
                    <Box className="showcaseimg"><img src={Shift} alt="" /></Box>
                </Box>
            </Box>
            <Box className="features_overlay">
                <Box className="features_content">
                    <Box className="showcase">
                        <Typography variant="h3">Advanced Feature,<br/> Projects!</Typography>
                        <Typography variant="body1">Project management is everywhere and all business needs it, we designed the latest and advanced projects here.</Typography>
                    </Box>
                </Box>
                <Box className="features_img">
                    <Box className="showcaseimg"><img src={Calender} alt="" /></Box>
                </Box>
            </Box>
            <Box className="features_overlay">
                <Box className="features_content">
                    <Box className="showcase">
                        <Typography variant="h3">Easily create employee<br/> swap offers</Typography>
                        <Typography variant="body1">User can make request for swap offer to the other user's shifts.</Typography>
                    </Box>
                </Box>
                <Box className="features_img">
                    <Box className="showcaseimg"><img src={Swap} alt="" /></Box>
                </Box>
            </Box>
            <Box className="features_overlay">
                <Box className="features_content">
                    <Box className="showcase">
                        <Typography variant="h3">Easily create employee<br/> Scheduled SMS</Typography>
                        <Typography variant="body1">Project management is everywhere and all business needs it, we designed the latest and advanced projects here.</Typography>
                    </Box>
                </Box>
                <Box className="features_img">
                    <Box className="showcaseimg"><img src={Sms} alt="" /></Box>
                </Box>
            </Box>
        </Box>

        <Box className="contact_us" id="Contact">
            <Box className="container">
                <Box className="contact_box">
                    <Typography variant="h3" color="white">Ready to make your entire<br/> team more effective?</Typography>
                    <Button className="standard_btn dark">Contact</Button>
                </Box>
            </Box>
        </Box>
        
        <Box className="Faqs space">
            <Box className="title"><Typography variant="h2" >FAQs</Typography></Box>
            <Box className="container">
              <Grid container> 
                <Grid item lg={2}></Grid>
                    <Grid item lg={8}>
                        <Accordion>
                            <AccordionSummary style={{background:'#fafafa'}}
                            expandIcon={<BsChevronDown />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography style={{fontWeight:700}}>What is employee scheduling?</Typography>
                            </AccordionSummary> 
                            <AccordionDetails>
                                <Typography>Employee scheduling is finding the right balance between staffing needs and staff availability, 
                                    taking account of the organisation’s objectives and organised from the organisational policy. 
                                    The use of good staff planning software allows you to take quantitative and qualitative staffing needs into account, 
                                    so the roster won’t show any holes or double shifts. Using online employee scheduling software, companies, 
                                    institutes and facilities can check and monitor the hours worked. Furthermore, holiday periods, 
                                    absences and breaks are clearly defined in order to be able to act in accordance with the legal requirements. 
                                    The roster is not only an important tool for managers and supervisors though, also employees can benefit from it. 
                                    It can be used to avoid misunderstanding and ambiguities.</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary style={{background:'#fafafa'}}
                            expandIcon={<BsChevronDown />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            >
                            <Typography style={{fontWeight:700}}>How to make a good staff planning?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Use professional employee scheduling software to create an organised roster, 
                                    including e.g. insight in the costs, availability, absence and required (number of) employees. 
                                    Creating a comprehensible staff planning takes a lot of time and is a complicated task. 
                                    Using the right roster software, you can quickly and easily create a strategic employee scheduling.</Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary style={{background:'#fafafa'}}
                            expandIcon={<BsChevronDown />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            >
                            <Typography style={{fontWeight:700}}>What should an employee schedule include?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <Typography>
                                An employee schedule should include all the details required for the staff to be able to properly carry out their duties. 
                                For instance, the right location, the right time and possible remarks.
                            </Typography>
                            </AccordionDetails>
                        </Accordion>   
                    </Grid>
                <Grid item lg={2}></Grid>
              </Grid>  
            </Box>
        </Box>  

        <Footer />
        </>
    );
}

export default Home;